// extracted by mini-css-extract-plugin
export var connectButton = "partners-and-promocodes-page-template-module--connectButton--c9f9e";
export var container = "partners-and-promocodes-page-template-module--container--3f2b6";
export var content = "partners-and-promocodes-page-template-module--content--491f1";
export var copyButton = "partners-and-promocodes-page-template-module--copyButton--c3cd8";
export var description = "partners-and-promocodes-page-template-module--description--ab9d7";
export var logoPlaceholder = "partners-and-promocodes-page-template-module--logoPlaceholder--6ff06";
export var partnerCard = "partners-and-promocodes-page-template-module--partnerCard--12e2e";
export var partnerDescription = "partners-and-promocodes-page-template-module--partnerDescription--3f7bb";
export var partnerLink = "partners-and-promocodes-page-template-module--partnerLink--12ac4";
export var partnerLogo = "partners-and-promocodes-page-template-module--partnerLogo--38672";
export var partnerName = "partners-and-promocodes-page-template-module--partnerName--9f3e9";
export var partnersBlock = "partners-and-promocodes-page-template-module--partnersBlock--d0c60";
export var partnersGrid = "partners-and-promocodes-page-template-module--partnersGrid--41690";
export var promocodeCard = "partners-and-promocodes-page-template-module--promocodeCard--af422";
export var promocodeCode = "partners-and-promocodes-page-template-module--promocodeCode--6c83e";
export var promocodeDescription = "partners-and-promocodes-page-template-module--promocodeDescription--b342e";
export var promocodeExpiry = "partners-and-promocodes-page-template-module--promocodeExpiry--e4a4e";
export var promocodeName = "partners-and-promocodes-page-template-module--promocodeName--0a04c";
export var promocodePartner = "partners-and-promocodes-page-template-module--promocodePartner--78e33";
export var promocodeValue = "partners-and-promocodes-page-template-module--promocodeValue--b1a5f";
export var promocodesBlock = "partners-and-promocodes-page-template-module--promocodesBlock--74e3f";
export var section = "partners-and-promocodes-page-template-module--section--b01ae";
export var subtitle = "partners-and-promocodes-page-template-module--subtitle--cfee8";
export var title = "partners-and-promocodes-page-template-module--title--ecc7a";